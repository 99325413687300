<template>
  <div>
    <h1>Sorry you have come to the wrong page</h1>
    <p>Turn back</p>
    <img src="https://alpha.nodenogg.in/files/cute_monkey.jpg" alt="" />
    <!-- FIXME: Put stuffed Monkey here -->
  </div>
</template>

<script>
export default {}
</script>

<style lang="css" scoped>
img {
  width: 50%;
}
</style>
