var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('draggable',{staticClass:"grid",attrs:{"id":"dragger","name":"grid"},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false},"update":_vm.nodePositionIndex}},[_vm._l((_vm.configPositions),function(value,index){return _c('div',{key:index},_vm._l((_vm.$options.myArray),function(nodes,index){return _c('div',{key:index},[(nodes.node_id == value.node_id)?_c('form',{staticClass:"nodes cell",style:({
            backgroundColor: nodes.color,
          })},[(nodes.read_mode == false)?[_c('textarea',{directives:[{name:"focus",rawName:"v-focus"},{name:"model",rawName:"v-model",value:(nodes.node_text),expression:"nodes.node_text"}],ref:"nodetext",refInFor:true,attrs:{"id":nodes.node_id,"placeholder":"Type your thoughts and ideas here! (auto saved every keystroke)"},domProps:{"value":(nodes.node_text)},on:{"focus":function($event){return _vm.editTrue(true)},"blur":function($event){return _vm.editTrue(false)},"input":[function($event){if($event.target.composing){ return; }_vm.$set(nodes, "node_text", $event.target.value)},_vm.editNode]}})]:[_c('p',{staticClass:"readmode",attrs:{"id":nodes.node_id},domProps:{"innerHTML":_vm._f("marked")(nodes.node_text)}})],_c('div',{staticClass:"btn-row"},[_c('SvgButton',{attrs:{"buttonClass":"nodes"},on:{"click":function($event){$event.preventDefault();_vm.deleteFlag(nodes.node_id), _vm.updateNodes()}}}),_c('SvgButton2',{attrs:{"buttonClass":"nodes"},on:{"click":function($event){$event.preventDefault();_vm.readFlag(nodes.node_id, nodes.read_mode), _vm.updateNodes()}}}),_c('v-swatches',{attrs:{"swatches":_vm.swatches,"shapes":_vm.shapes,"show-border":"","show-fallback":"","fallback-input-type":"color"},on:{"input":function($event){return _vm.chooseColor(_vm.color, nodes.node_id)}},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}},[_c('SvgButton3',{attrs:{"slot":"trigger","buttonClass":"nodes"},on:{"click":function($event){$event.preventDefault();}},slot:"trigger"})],1)],1),_c('div',{staticClass:"allemoji"},_vm._l((_vm.configEmoji),function(emojis,index){return _c('div',{key:index,staticClass:"eachemoji"},[(emojis.node_id == nodes.node_id)?[_vm._v(_vm._s(emojis.emoji_text))]:_vm._e()],2)}),0)],2):_vm._e()])}),0)}),_vm._l((_vm.othernodes_filtered),function(othernodes,index){return _c('div',{key:index.nodeid},[_c('div',{staticClass:"othernodes cell",style:({
          backgroundColor: othernodes.color,
        })},[_c('p',{staticClass:"readmode",domProps:{"innerHTML":_vm._f("marked")(othernodes.node_text)}}),_c('div',{staticClass:"eeee"},[_c('input',{attrs:{"name":"id","readonly":"","hidden":""},domProps:{"value":othernodes.node_id}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input),expression:"input"}],staticClass:"regular-input",attrs:{"id":"emojifield","readonly":""},domProps:{"value":(_vm.input)},on:{"input":function($event){if($event.target.composing){ return; }_vm.input=$event.target.value}}}),_c('emoji-picker',{attrs:{"search":_vm.search},on:{"emoji":_vm.append},scopedSlots:_vm._u([{key:"emoji-invoker",fn:function(ref){
        var clickEvent = ref.events.click;
return _c('div',{staticClass:"emoji-invoker",on:{"click":function($event){$event.stopPropagation();return clickEvent($event)}}},[_c('svg',{attrs:{"height":"24","viewBox":"0 0 24 24","width":"24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0 0h24v24H0z","fill":"none"}}),_c('path',{attrs:{"d":"M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"}})])])}},{key:"emoji-picker",fn:function(ref){
        var emojis = ref.emojis;
        var insert = ref.insert;
        var display = ref.display;
return _c('div',{},[_c('div',{staticClass:"emoji-picker",style:({ top: display.y + 'px', left: display.x + 'px' })},[_c('div',{staticClass:"emoji-picker__search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],attrs:{"type":"text"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})]),_c('div',_vm._l((emojis),function(emojiGroup,category){return _c('div',{key:category},[_c('h5',[_vm._v(_vm._s(category))]),_c('div',{staticClass:"emojis"},_vm._l((emojiGroup),function(emoji,emojiName){return _c('span',{key:emojiName,attrs:{"title":emojiName},on:{"click":function($event){insert(emoji), _vm.sentReact(othernodes.node_id)}}},[_vm._v(_vm._s(emoji))])}),0)])}),0)])])}}],null,true)}),_c('div',{staticClass:"allemoji"},_vm._l((_vm.configEmoji),function(emojis,index){return _c('div',{key:index,staticClass:"eachemoji"},[(othernodes.node_id == emojis.node_id)?_c('p',[_vm._v(" "+_vm._s(emojis.emoji_text)+" ")]):_vm._e()])}),0)],1)])])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }