var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mode == 'move')?_c('div',{ref:"container",staticClass:"outer",on:{"!wheel":function($event){return _vm.onWheel($event)},"&touchstart":function($event){return _vm.onTouchStart($event)},"mousedown":_vm.onMouseDown,"touchmove":_vm.onTouchMove,"mousemove":_vm.onMouseMove,"&!touchend":function($event){return _vm.onTouchEnd($event)},"&!mouseup":function($event){return _vm.onMouseUp($event)},"!click":function($event){return _vm.handleEventCapture($event)},"!touchend":function($event){return _vm.handleEventCapture($event)}}},[_c('div',{ref:"innerContainer",class:{ inner: true, active: true },style:({
      width: (_vm.width + "px"),
      height: (_vm.height + "px"),
      transform: ("translate(" + (_vm.translation.x) + "px, " + (_vm.translation.y) + "px) scale(" + _vm.scale + ")"),
    })},[_vm._t("default")],2)]):_c('div',{ref:"container",staticClass:"outer"},[_c('div',{ref:"innerContainer",class:{ inner: true, active: true },style:({
      width: (_vm.width + "px"),
      height: (_vm.height + "px"),
      transform: ("translate(" + (_vm.translation.x) + "px, " + (_vm.translation.y) + "px) scale(" + _vm.scale + ")"),
    })},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }