<template>
  <div>
    <div v-show="showtipsstate" class="nodes welcome">
      <img src="https://alpha.nodenogg.in/files/collect.png" />

      <p>
        Hey! Welcome <span class="inline">👋</span> to
        <strong>magpie mode</strong> this is where you collect your thoughts and
        ideas, without distraction and as quickly and easily as possible.
      </p>
      <p>
        Tap the new node icon
        <svg
          class="example"
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          viewBox="0 0 143 106"
        >
          <g transform="translate(-1345 -843)">
            <g class="a" transform="translate(1345 865)">
              <rect class="d" width="127" height="84" />
              <rect class="e" x="0.5" y="0.5" width="126" height="83" />
            </g>
            <g class="b" transform="translate(1361 843)">
              <rect class="d" width="127" height="84" />
              <rect class="e" x="3.5" y="3.5" width="120" height="77" />
            </g>
            <line class="c" x2="41" transform="translate(1406.5 884.5)" />
            <line class="c" y2="41" transform="translate(1426.5 863.5)" />
          </g>
        </svg>
        (shortcut <strong>n</strong>) to start (create as many nodes as you want
        for each thought)
      </p>

      <p>
        You can use
        <strong
          ><a href="https://guides.github.com/features/mastering-markdown/"
            >markdown</a
          ></strong
        >
        to quickly format your text and you can upload or add images from
        websources, you can even use plain html and embed codes.
      </p>
      <p>
        To see what <strong>everyone else</strong> is collecting and to start
        organising your thoughts <strong>spatially</strong>, switch to organise
        mode.
      </p>
      <p>
        If you want to hide any nodes tap or press the hide icon
        <svg
          class="example"
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          viewBox="0 0 158 158"
        >
          <g transform="translate(-1591.6 -842.601)">
            <path
              d="M55,9.465c30.376,0,71.063,15.16,71.063,45.535S85.376,101.028,55,101.028-15.718,85.376-15.718,55,24.624,9.465,55,9.465Z"
              transform="translate(1610 875)"
            />
            <g class="z" transform="translate(1616 865)">
              <path
                class="x"
                d="M 55.00000381469727 97.52814483642578 C 47.5827751159668 97.52814483642578 39.61429595947266 96.55204010009766 31.95606803894043 94.70535278320312 C 23.91633796691895 92.76667022705078 16.30660820007324 89.87577056884766 9.949525833129883 86.34516906738281 C 3.042046308517456 82.50887298583984 -2.367432832717896 77.99252319335938 -6.128662109375 72.92156219482422 C -10.16918277740479 67.47402191162109 -12.21789073944092 61.44435501098633 -12.21789073944092 55 C -12.21789073944092 39.30952072143555 -0.1744952797889709 29.4342098236084 9.928650856018066 23.91279411315918 C 13.16560935974121 22.1437931060791 16.71875381469727 20.5400218963623 20.4893798828125 19.14606475830078 C 24.12692070007324 17.80127334594727 27.97865104675293 16.64689826965332 31.93756675720215 15.71498107910156 C 39.58069229125977 13.91579341888428 47.55554580688477 12.96479320526123 55.00000381469727 12.96479320526123 C 62.44902420043945 12.96479320526123 70.44358825683594 13.91604328155518 78.11937713623047 15.71568870544434 C 86.21414947509766 17.61356353759766 93.86746215820312 20.4495849609375 100.2518997192383 23.91716766357422 C 110.4301071166992 29.44523048400879 122.5629806518555 39.32521057128906 122.5629806518555 55 C 122.5629806518555 61.43754196166992 120.4986724853516 67.46379089355469 116.4273986816406 72.911376953125 C 112.6376113891602 77.98233795166016 107.1883773803711 82.50062561035156 100.2310638427734 86.34071350097656 C 93.83364868164062 89.87177276611328 86.18119049072266 92.76396179199219 78.10095977783203 94.70460510253906 C 70.40991973876953 96.55177307128906 62.42173385620117 97.52814483642578 55.00000381469727 97.52814483642578 Z"
              />
              <path
                class="w"
                d="M 54.99999618530273 16.46478271484375 C 28.28687286376953 16.46478271484375 -8.717903137207031 29.85636138916016 -8.717903137207031 55.0000114440918 C -8.717903137207031 80.46525573730469 28.28687286376953 94.02813720703125 54.99999618530273 94.02813720703125 C 81.85781860351562 94.02813720703125 119.0629959106445 80.46525573730469 119.0629959106445 55.0000114440918 C 119.0629959106445 29.85636138916016 81.85781860351562 16.46478271484375 54.99999618530273 16.46478271484375 M 54.99999618530273 9.46478271484375 C 85.37564849853516 9.46478271484375 126.0629959106445 24.62433624267578 126.0629959106445 55.0000114440918 C 126.0629959106445 85.37565612792969 85.37564849853516 101.0281372070312 54.99999618530273 101.0281372070312 C 24.62434387207031 101.0281372070312 -15.71790313720703 85.37565612792969 -15.71790313720703 55.0000114440918 C -15.71790313720703 24.62433624267578 24.62434387207031 9.46478271484375 54.99999618530273 9.46478271484375 Z"
              />
            </g>
            <circle
              cx="21.5"
              cy="21.5"
              r="21.5"
              transform="translate(1650 898)"
            />
            <line
              class="y"
              x1="138"
              y2="138"
              transform="translate(1601.5 852.5)"
            />
          </g>
        </svg>
      </p>

      <SvgButton @click="hideTips()" />
    </div>
    <div v-show="!showtipsstate"></div>
  </div>
</template>

<script>
import marked from 'marked'
import { mapState } from 'vuex'
import SvgButton from '@/components/SvgButton'

export default {
  data: function () {
    return {
      // showtipsstate: null,
    }
  },

  mounted() {
    // this.tipsplease = this.hidetipsstate
  },
  filters: {
    marked: marked,
  },

  computed: mapState({
    showtipsstate: (state) => state.showtipsstate,
  }),

  methods: {
    hideTips() {
      var e = false
      this.$store.dispatch('showTipsstate', e)
    },
  },
  components: {
    SvgButton,
  },
}
</script>

<style lang="css" scoped>
img {
  max-width: 400px;
  padding: 0 50;
}

.welcome {
  /* max-width: 450px; */
  width: 95%;
  margin-top: 1em;
  margin-left: 0.5em;
  background-color: #6fcf97;
  border: 2px solid black;
  padding: 1em;
}

.inline {
  font-size: 2em;
}

.vdr {
  padding: 0 0.5em;
}

.example {
  width: 30px;
}

.icon {
  margin-left: 1em;
  padding: 0.5em;
  cursor: pointer;
}

.a {
  fill: #333;
  stroke: #707070;
}
.b {
  fill: #fff;
  stroke: #333;
}
.b,
.c {
  stroke-width: 7px;
}
.c,
.e {
  fill: none;
}
.c {
  stroke: #2d9cdb;
}
.d {
  stroke: none;
}

.z {
  fill: #fff;
}
.y {
  fill: none;
  stroke: #2d9cdb;
  stroke-linecap: round;
  stroke-width: 14px;
}
.x,
.w {
  stroke: none;
}

.btn-row {
  margin-bottom: 5px;
  padding: 0px 0px 15px 10px;
  border-radius: 4px;
}
</style>
