var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"othernodes",staticClass:"node"},[(_vm.toolmode == 'move')?_c('div',_vm._l((_vm.$options.positionsArray),function(value,index){return _c('div',{key:index},_vm._l((_vm.othernodes_filtered),function(nodes,index){return _c('div',{key:index},[(nodes.node_id == value.node_id)?_c('draggable',{style:({
            border: _vm.border,
            backgroundColor: nodes.color,
          }),attrs:{"w":value.width,"h":value.height,"x":value.x_pos,"y":value.y_pos,"z":value.z_index,"scale":_vm.scale,"draggable":false,"resizable":false,"min-width":200,"min-height":220},on:{"activated":function($event){return _vm.onActivated(nodes.node_id, value.z_index)},"dragging":_vm.onDrag,"resizing":_vm.onResize,"dragstop":_vm.onDragstop,"resizestop":_vm.onResizestop}},[_c('p',{staticClass:"read",attrs:{"id":nodes.node_id},domProps:{"innerHTML":_vm._f("marked")(nodes.node_text)}}),_c('div',{staticClass:"react"},[_c('div',{staticClass:"eeee"},[_c('input',{attrs:{"name":"id","readonly":"","hidden":""},domProps:{"value":nodes.node_id}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input),expression:"input"}],staticClass:"regular-input",attrs:{"id":"emojifield","readonly":""},domProps:{"value":(_vm.input)},on:{"input":function($event){if($event.target.composing){ return; }_vm.input=$event.target.value}}}),_c('emoji-picker',{attrs:{"search":_vm.search},on:{"emoji":_vm.append},scopedSlots:_vm._u([{key:"emoji-invoker",fn:function(ref){
          var clickEvent = ref.events.click;
return _c('div',{staticClass:"emoji-invoker",on:{"click":function($event){$event.stopPropagation();return clickEvent($event)}}},[_c('svg',{attrs:{"height":"24","viewBox":"0 0 24 24","width":"24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0 0h24v24H0z","fill":"none"}}),_c('path',{attrs:{"d":"M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"}})])])}},{key:"emoji-picker",fn:function(ref){
          var emojis = ref.emojis;
          var insert = ref.insert;
          var display = ref.display;
return _c('div',{},[_c('div',{staticClass:"emoji-picker",style:({ top: display.y + 'px', left: display.x + 'px' })},[_c('div',{staticClass:"emoji-picker__search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],attrs:{"type":"text"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})]),_c('div',_vm._l((emojis),function(emojiGroup,category){return _c('div',{key:category},[_c('h5',[_vm._v(_vm._s(category))]),_c('div',{staticClass:"emojis"},_vm._l((emojiGroup),function(emoji,emojiName){return _c('span',{key:emojiName,attrs:{"title":emojiName},on:{"click":function($event){insert(emoji), _vm.sentReact(nodes.node_id)}}},[_vm._v(_vm._s(emoji))])}),0)])}),0)])])}}],null,true)}),_c('div',{staticClass:"allemoji"},_vm._l((_vm.configEmoji),function(emojis,index){return _c('div',{key:index,staticClass:"eachemoji"},[(nodes.node_id == emojis.node_id)?_c('p',[_vm._v(" "+_vm._s(emojis.emoji_text)+" ")]):_vm._e()])}),0)],1)])]):_vm._e()],1)}),0)}),0):_vm._e(),(_vm.toolmode != 'move')?_c('div',_vm._l((_vm.$options.positionsArray),function(value,index){return _c('div',{key:index},_vm._l((_vm.othernodes_filtered),function(nodes,index){return _c('div',{key:index},[(nodes.node_id == value.node_id)?_c('draggable',{style:({
            border: _vm.border,
            backgroundColor: nodes.color,
          }),attrs:{"w":value.width,"h":value.height,"x":value.x_pos,"y":value.y_pos,"z":value.z_index,"scale":_vm.scale,"min-width":200,"min-height":220},on:{"activated":function($event){return _vm.onActivated(nodes.node_id, value.z_index)},"dragging":_vm.onDrag,"resizing":_vm.onResize,"dragstop":_vm.onDragstop,"resizestop":_vm.onResizestop}},[_c('p',{staticClass:"read",attrs:{"id":nodes.node_id},domProps:{"innerHTML":_vm._f("marked")(nodes.node_text)}}),_c('div',{staticClass:"react"},[_c('div',{staticClass:"eeee"},[_c('input',{attrs:{"name":"id","readonly":"","hidden":""},domProps:{"value":nodes.node_id}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input),expression:"input"}],staticClass:"regular-input",attrs:{"id":"emojifield","readonly":""},domProps:{"value":(_vm.input)},on:{"input":function($event){if($event.target.composing){ return; }_vm.input=$event.target.value}}}),_c('emoji-picker',{attrs:{"search":_vm.search},on:{"emoji":_vm.append},scopedSlots:_vm._u([{key:"emoji-invoker",fn:function(ref){
          var clickEvent = ref.events.click;
return _c('div',{staticClass:"emoji-invoker",on:{"click":function($event){$event.stopPropagation();return clickEvent($event)}}},[_c('svg',{attrs:{"height":"24","viewBox":"0 0 24 24","width":"24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0 0h24v24H0z","fill":"none"}}),_c('path',{attrs:{"d":"M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"}})])])}},{key:"emoji-picker",fn:function(ref){
          var emojis = ref.emojis;
          var insert = ref.insert;
          var display = ref.display;
return _c('div',{},[_c('div',{staticClass:"emoji-picker",style:({ top: display.y + 'px', left: display.x + 'px' })},[_c('div',{staticClass:"emoji-picker__search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],attrs:{"type":"text"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})]),_c('div',_vm._l((emojis),function(emojiGroup,category){return _c('div',{key:category},[_c('h5',[_vm._v(_vm._s(category))]),_c('div',{staticClass:"emojis"},_vm._l((emojiGroup),function(emoji,emojiName){return _c('span',{key:emojiName,attrs:{"title":emojiName},on:{"click":function($event){insert(emoji), _vm.sentReact(nodes.node_id)}}},[_vm._v(_vm._s(emoji))])}),0)])}),0)])])}}],null,true)}),_c('div',{staticClass:"allemoji"},_vm._l((_vm.configEmoji),function(emojis,index){return _c('div',{key:index,staticClass:"eachemoji"},[(nodes.node_id == emojis.node_id)?_c('p',[_vm._v(" "+_vm._s(emojis.emoji_text)+" ")]):_vm._e()])}),0)],1)])]):_vm._e()],1)}),0)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }